import { useContext } from "react";
import { MediaTypes, Products } from "./components";
import Header from "./components/Header";
import { marketingToolContext } from "./MarketingToolContext";
import { useIntl } from "react-intl";
import { LocalizationKey } from "helpers/Enums";
import { Loading } from "components";
import { useNavigate } from "react-router-dom";


let MarketingTool = () => {
    let service = useContext(marketingToolContext);
    let { formatMessage } = useIntl();
    let navigate = useNavigate();

    return (
        <div className="container-fluid special-p bundleDetail">
            {service.isLoading === true && <Loading />}
            {service.isLoading === false &&
                <>
                    <Header />
                    <div className="details__container">
                        <div className="row">
                            {service.currentTab === '' &&
                                <>
                                    {service.marketingTool.products && service.marketingTool.products.length !== 0 &&
                                        <div className="col-listproduct">
                                            <h4 className="text-left">{formatMessage({ id: LocalizationKey.ProductsInMarketingTool })}</h4>

                                            <Products />

                                            {service.marketingTool.products.length > 9 && service.showMore === false &&
                                                <button
                                                    className="btn btn-block btn-link"
                                                    onClick={() => service.handleChangeShowMore(!service.showMore)}
                                                >
                                                    {formatMessage({ id: LocalizationKey.ShowAll })} {service.marketingTool.products.length} {formatMessage({ id: LocalizationKey.Products })}
                                                </button>
                                            }
                                            {service.showMore === true &&
                                                <button
                                                    className="btn btn-block btn-link"
                                                    onClick={() => service.handleChangeShowMore(!service.showMore)}
                                                >
                                                    {formatMessage({ id: LocalizationKey.ShowLess })}
                                                </button>
                                            }
                                        </div>
                                    }
                                </>
                            }
                            <MediaTypes />
                        </div>
                    </div>
                </>
            }
            <hr />
            <div className="row">
                <div className="col-lg-9">
                    <a onClick={(e) => navigate(-1)} style={{ cursor: 'pointer' }}>
                        <span className="class= badge badge-dark">
                            <i className="fa fa-chevron-left"></i> {formatMessage({ id: LocalizationKey.BackToSearch })}
                        </span>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default MarketingTool;