import { useContext } from "react";
import Moment from "react-moment";
import { marketingToolContext } from "../MarketingToolContext";
import { applicationContext } from "helpers/services";
import { DownloadAll, DownloadSelectedProducts } from "components";
import { useIntl } from "react-intl";
import { LocalizationKey, UserType } from "helpers/Enums";


let Header = () => {
    let service = useContext(marketingToolContext);
    let appService = useContext(applicationContext);
    let { formatMessage } = useIntl();


    return (
        <div className="details__header">
            <div>
                <div className={`brand-sign c-${service.marketingTool.brand?.toLowerCase()}`}>
                    <div className={`logo logo-${service.marketingTool.brand?.toLowerCase()}-lg-w`}>
                        <span></span>
                    </div>
                </div>
                <div className="details__header__container">
                    <h4 className="product-code">
                        <span>
                            <Moment date={service.marketingTool.date} format='MMM YYYY' />
                        </span>
                    </h4>
                    <DownloadAll
                        component="button"
                        linkedTo="MarketingTool"
                        id={service.marketingTool.marketingToolId}
                    />
                    {service.selectedProducts.length > 0 &&
                        <DownloadSelectedProducts
                            productIds={service.selectedProducts}
                        />
                    }
                    <h1 className="product-title">{service.marketingTool.title}</h1>
                </div>

            </div>
            <div className="details__header__selectedbundle">
                <button
                    className={service.currentTab === ''
                        ? "btn text-uppercase font-weight-bold btn-xs btn-dark"
                        : 'btn btn-light text-uppercase font-weight-bold btn-xs'
                    }
                    onClick={(e) => service.changeTab('')}
                >
                    {formatMessage({ id: LocalizationKey.ShowAll })}
                </button>
                {service.marketingTool.mediaTypes &&
                    service.marketingTool.mediaTypes.map(mediaType =>
                        <button
                            type="button"
                            key={mediaType.type}
                            className={service.currentTab === mediaType.type
                                ? "btn text-uppercase font-weight-bold btn-xs btn-dark"
                                : 'btn btn-light text-uppercase font-weight-bold btn-xs'
                            }
                            onClick={(e) => service.changeTab(mediaType.type)}
                        >
                            {appService.mediaTypes.getValueByKey(mediaType.type)}
                        </button>
                    )}
            </div>
        </div>
    );
}

export default Header;